import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'

import Product from './Product/Product'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))

const Products = props => {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                {props.products.map(product => (
                    <Grid key={product.id} item xs={12} sm={6} md={4}>
                        <Product
                            title={product.title}
                            slug={product.slug}
                            description={product.description}
                            imageThumbnail={product.imageThumbnail}
                            price={product.price}
                            isService={product.isService}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

export default Products